import { useState } from "react";
import { entries, notEqual, values } from "./javascript";
import { allValidations } from "./formValidations";
import { confirmDialog } from "primereact/confirmdialog";
import moment from "moment";

export const equal = (obj1, obj2 = 0) => obj1 === obj2;

export const showFormErrors = (data, setData) => {
    let formErrors = {};
    entries(data).forEach(([key, value]) => {
        formErrors = {
            ...formErrors,
            ...allValidations(key, value, data),
        };
    });

    setData({ ...data, formErrors });
    return !values(formErrors).some((v) => notEqual(v, ""));
};
export const showFormErrors1 = (data, index, setData) => {
    let formErrors = {};
    entries(data[index]).forEach(([key, value]) => {
        formErrors = {
            ...formErrors,
            ...allValidations(key, value, data[index]),
        };
    });

    setData({ ...data[index], formErrors }, index);
    return !Object.values(formErrors).some((v) => notEqual(v, ""));
};

export const OpenSections = () => {
    const [openSections, setOpenSections] = useState([]);

    const openSection = (sectionId) => {
        let sections = [...openSections];
        if (sections.includes(sectionId)) {
            sections.splice(sections.indexOf(sectionId), 1);
        } else {
            sections.push(sectionId);
        }
        setOpenSections(sections);
    };
    return openSection;
};

export const validateForm = (data, text, setErr) => {
    let errors = {};

    if (!data?.name) {
        errors.name = "Name is required";
    }
    if (!data.class_type) {
        errors.class_type = "Class Type is required";
    }
    if (!data.class_type) {
        errors.class_type = "Class Type is required";
    }
    if ((data?.class_type === "after_school" || data?.class_type === "semi_private") && !data?.no_of_seats) {
        errors.no_of_seats = "No. of Seats is required";
    }
    if (
        (data?.class_type === "after_school" || data?.class_type === "semi_private") &&
        data?.is_waiting_list === true &&
        !data?.deposit_amount
    ) {
        errors.deposit_amount = "Deposit amount is required";
    }

    if (!text) {
        errors.text = "Description is required";
    }

    // if (data?.online_available && !data?.monthly_payment) {
    //     errors.monthly_payment = "Monthly Payment is required";
    // }

    setErr(errors);

    return Object.keys(errors).length === 0;
};

export const validationForm1 = (requiredFields, data, setErr) => {
    let errors = {};

    Object.entries(requiredFields).forEach(([fieldName, fieldLabel]) => {
        if (!data[fieldName]) {
            errors[fieldName] = `${fieldLabel} is required`;
        }
    });

    setErr(errors);

    return Object.keys(errors).length === 0;
};

export const validateFields = (data, requiredFields) => {
    let errors = {};

    Object.entries(requiredFields).forEach(([fieldName, errorMsg]) => {
        if (!data[fieldName]) {
            errors[fieldName] = errorMsg;
        }
    });

    return errors;
};

export const FormValidator = (formData, validationRules) => {
    const data = [...formData];
    let valid = true;

    for (let index = 0; index < data.length; index++) {
        for (const key in validationRules) {
            if (data[index][key] === null) {
                data[index][`${key}Check`] = validationRules[key];
                valid = false;
            } else {
                data[index][`${key}Check`] = null;
            }
        }
    }

    return { data, valid };
};

export const confirmDelete = (onDeleteAction, confirmationMessage, position, confirmationHeader = "Delete Confirmation") => {
    confirmDialog({
        message: confirmationMessage,
        icon: "pi pi-info-circle",
        header: confirmationHeader,
        acceptClassName: "bg-main",
        position,
        accept: () => {
            onDeleteAction();
        },
        reject: () => { },
    });
};

export const formValidation = (formVal, setInstrument) => {
    const data = [...formVal];
    let valid = true;
    for (let index = 0; index < data.length; index++) {
        if (data[index].name === "") {
            data[index].nameCheck = "Instrument Name is required";
            valid = false;
        } else {
            data[index].nameCheck = "";
            valid = true;
        }
        if (data[index].purchase_amount === null) {
            data[index].purchaseCheck = "Purchase Amount is required";
            valid = false;
        } else {
            data[index].purchaseCheck = "";
            valid = true;
        }
        if (data[index].monthly_installment === null) {
            data[index].RentCheck = "Rent Amount is required";
            valid = false;
        } else {
            data[index].RentCheck = "";
            valid = true;
        }
        if (data[index].number_of_installment === null) {
            data[index].NumberInstallmentCheck = "No. of Installment is required";
            valid = false;
        } else {
            data[index].NumberInstallmentCheck = "";
            valid = true;
        }
        if (data[index].deposit_amount === null) {
            data[index].DepositCheck = "Deposit Amount is required";
            valid = false;
        } else {
            data[index].DepositCheck = "";
            valid = true;
        }
    }
    setInstrument(data);
    return valid;
};
export const getSearchedData = (arr, keyword, keys) => {
    if (keyword.length) {
        arr = arr.filter((obj) => {
            return keys.some((k) => {
                const value = obj[k];
                const isString = typeof value === "string";
                const isNumber = !isNaN(value);
                const isDate = !isNaN(Date.parse(value)); // Check if the value is a valid date

                if (isDate) {
                    const dateObject = new Date(value);
                    const formattedDate = `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()}`;
                    return formattedDate.includes(keyword); // Check if the formatted date includes the keyword
                } else if (Array.isArray(value)) {
                    return value.some((element) => {
                        if (typeof element === "object") {
                            // If the element is an object, search its properties
                            return Object.values(element).some((nestedValue) => {
                                const nestedIsString = typeof nestedValue === "string";
                                const nestedIsNumber = !isNaN(nestedValue);
                                return (
                                    (nestedIsString && nestedValue.toLowerCase().includes(keyword.toLowerCase())) ||
                                    (nestedIsNumber && nestedValue === Number(keyword))
                                );
                            });
                        } else {
                            // If the element is not an object, perform regular search
                            const nestedIsString = typeof element === "string";
                            const nestedIsNumber = !isNaN(element);
                            return (
                                (nestedIsString && element.toLowerCase().includes(keyword.toLowerCase())) ||
                                (nestedIsNumber && element === Number(keyword))
                            );
                        }
                    });
                } else {
                    // If the value is not an array or date, perform regular search
                    return (
                        (isString && value.toLowerCase().includes(keyword.toLowerCase())) ||
                        (isNumber && value === Number(keyword))
                    );
                }
            });
        });
    }
    return arr;
};

// export const getSearchedData = (arr, keyword, keys) => {
//     if (keyword.length) {
//         arr = arr.filter((obj) => {
//             return keys.some((k) => {
//                 const value = obj[k];
//                 const isString = typeof value === "string";
//                 const isNumber = !isNaN(value);
//                 const isArray = Array.isArray(value);

//                 if (isArray) {
//                     return value.some((element) => {
//                         if (typeof element === "object") {
//                             // If the element is an object, search its properties
//                             return Object.values(element).some((nestedValue) => {
//                                 const nestedIsString = typeof nestedValue === "string";
//                                 const nestedIsNumber = !isNaN(nestedValue);
//                                 return (
//                                     (nestedIsString && nestedValue.toLowerCase().includes(keyword.toLowerCase())) ||
//                                     (nestedIsNumber && nestedValue === Number(keyword))
//                                 );
//                             });
//                         } else {
//                             // If the element is not an object, perform regular search
//                             const nestedIsString = typeof element === "string";
//                             const nestedIsNumber = !isNaN(element);
//                             return (
//                                 (nestedIsString && element.toLowerCase().includes(keyword.toLowerCase())) ||
//                                 (nestedIsNumber && element === Number(keyword))
//                             );
//                         }
//                     });
//                 } else {
//                     // If the value is not an array, perform regular search
//                     return (
//                         (isString && value.toLowerCase().includes(keyword.toLowerCase())) ||
//                         (isNumber && value === Number(keyword))
//                     );
//                 }
//             });
//         });
//     }
//     return arr;
// };
// export const getSearchedData = (arr, keyword, keys) => {
//     if (keyword.length) {
//         arr = arr.filter((obj) => {
//             return keys.some((k) => {
//                 const value = obj[k];
//                 const isString = typeof value === "string";
//                 const isNumber = !isNaN(value);
//                 return (
//                     (isString && value.toLowerCase().includes(keyword.toLowerCase())) || (isNumber && value === Number(keyword))
//                 );
//                 // const value = obj[k];
//                 // return value?.toLowerCase()?.includes(keyword?.toLowerCase());
//             });
//         });
//     }
//     return arr;
// };
export const removeEmpty = (obj) => {
    const newObj = {};
    Object.entries(obj).forEach(([k, v]) => {
        if (v === Object(v)) {
            newObj[k] = removeEmpty(v);
        } else if (v !== "" && v !== null) {
            newObj[k] = obj[k];
        }
    });
    return newObj;
};
export const timeConvertToDate = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const currentDate = new Date();
    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);
    currentDate.setSeconds(seconds);
    return currentDate;
};

export const getTime = (date) => {
    return moment(new Date(date)).format("hh:mm:ss");
};

export function adjustTime(e) {
    let currentMin = e.value.getMinutes();
    let startTime = e.value;
    if (currentMin % 5 !== 0) {
        startTime = new Date(e.value.getTime() + (5 - (currentMin % 5)) * 60000);
    }
    return startTime;
}
export const formattedPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length === 0) {
        return phoneNumber;
    } else if (phoneNumber.length < 4) {
        return "(" + phoneNumber + ")";
    } else if (phoneNumber.length < 7) {
        return "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3);
    } else {
        return "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6);
    }
};
export const removeSpaces = (str) => {
    return str.replace(/\s/g, "");
};
export const removeDashAndAddBrackets = (value) => {
    if (value?.startsWith("-")) {
        value = value[1];
        value = "(" + value + ")";
    }
    return value;
};

export function isFileObject(obj) {
    return obj instanceof File;
}

export const TimeShowFormat = (date) => {
    var hours = date?.getHours();
    var minutes = date?.getMinutes();

    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
};
export const convertTimeTo12HourFormat = (time) => {
    const splitTime = time.split(":");
    const hour = parseInt(splitTime[0]);

    if (hour === 0) {
        return `12:00 AM`;
    } else if (hour < 12) {
        return `${hour}:00 AM`;
    } else if (hour === 12) {
        return `12:00 PM`;
    } else {
        return `${hour - 12}:00 PM`;
    }
};

export const setTime = (time, date = new Date()) => {
    let hour = time.split(":")[0];
    let min = time.split(":")[1];
    date.setHours(hour);
    date.setMinutes(min);
    return date;
};
export const sortByTime = (sessions) => {
    return sessions.sort((a, b) => {
        const aTime = new Date(a.start_time);
        const bTime = new Date(b.start_time);

        return aTime - bTime;
    });
};
export function isNotNull(element) {
    return element !== null;
}

export const checkfun = (user) => {
    return user == 0 ? false : true;
};
export const checkfun1 = (user) => {
    return user == true ? 1 : 0;
};
export const getWeekAndDay = (date) => {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const currentDate = new Date(date);
    const dayOfWeek = daysOfWeek[currentDate.getDay()];

    const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const daysPassed = Math.floor((currentDate - firstDayOfYear) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil((daysPassed + firstDayOfYear.getDay() + 1) / 7);

    return {
        week: weekNumber,
        day: dayOfWeek,
    };
};

export const getWeekOfMonth = (date) => {
    const firstDayOfMonth = moment(date).startOf("month");
    const dayOfWeekFirstDay = firstDayOfMonth.day();
    const dayOfMonth = moment(date).date();
    const weekNumber = Math.ceil((dayOfMonth + dayOfWeekFirstDay) / 7);
    return weekNumber;
};

// Function to get the day name of a date
export const getDayName = (date) => {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayIndex = date.getDay();
    return daysOfWeek[dayIndex];
};

export const getOrdinalSuffix = (number) => {
    if (number >= 11 && number <= 13) {
        return "th";
    }
    switch (number) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
};

export const getClassName = (value) => {
    return value === "during_school"
        ? "Small Group Lesson (During School)"
        : value === "after_school"
            ? "Small Group Lesson (After School)"
            : value === "semi_private_during_school"
                ? "Semi-Private Lesson (During School)"
                : "Semi-Private Lesson (After School)";
};
export function isSameMonthYear(cardExpMonth, cardExpYear) {
    const currentDate = new Date();
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const currentYear = currentDate.getFullYear().toString();
    console.log(cardExpMonth === currentMonth , cardExpYear,currentYear, "cardExpMonth")
    return cardExpMonth <= currentMonth && cardExpYear <= currentYear;
}

export const formatClassTypes = (types) => {
    return types.map((item) => ({
        ...item,
        name: getClassName(item.value),
    }));
};

//function to fix One day before issue
export const getFormattedDate = (date) => {
    let updatedDate = null;
    if (date) {
        const offset = moment(new Date(date)).utcOffset();
        updatedDate = moment(date).subtract(offset, "m");
        updatedDate = new Date(updatedDate);
    }
    return updatedDate;
};
